<template>
  <div v-if="loaded" class="start-a-business">
    <div class="main-container">
      <h2 class="sl__h2 container-header">
        Start or Add a Business
      </h2>
      <div class="container-body">
        <p class="sl__p">
          Create your business from the ground up—from writing a business plan to making it
          official with the state, whether you’re a do-it-yourselfer or want a helping hand.
        </p>
        <p class="sl__p">
          If you have an existing business, add it to your online account and you’ll gain
          access to the tools and resources to grow and maintain it.
        </p>
      </div>
    </div>

    <button-container
      :next-button-label="nextButtonLabel"
      :show-previous-button="showPreviousButton"
      @next="next"
    />
  </div>

  <div v-else class="center-spinner">
    <ct-centered-spinner />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'StartOrAddBusiness',
  components: {
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
    ButtonContainer: () => import('@/components/StagelineV2/domainsFirst/shared/ButtonContainer'),
  },
  data () {
    return {
      nextButtonLabel: 'Start or Add My Business',
      showPreviousButton: false,
      loaded: false,
    }
  },
  mounted() {
    this.loaded = true
  },
  methods: {
    ...mapActions('stagelineCreateCompany', [
      'setCurrentSlide',
    ]),
    next() {
      this.setCurrentSlide('SelectJurisdiction')
    },
  },
}
</script>

<style lang="scss" scoped>
.sl__p {
  text-align: left;

  &:first-of-type {
    margin-bottom: 1.0em;
  }
}
</style>
