var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "div",
        { staticClass: "start-a-business" },
        [
          _vm._m(0),
          _c("button-container", {
            attrs: {
              "next-button-label": _vm.nextButtonLabel,
              "show-previous-button": _vm.showPreviousButton,
            },
            on: { next: _vm.next },
          }),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "center-spinner" },
        [_c("ct-centered-spinner")],
        1
      )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "main-container" }, [
      _c("h2", { staticClass: "sl__h2 container-header" }, [
        _vm._v("\n      Start or Add a Business\n    "),
      ]),
      _c("div", { staticClass: "container-body" }, [
        _c("p", { staticClass: "sl__p" }, [
          _vm._v(
            "\n        Create your business from the ground up—from writing a business plan to making it\n        official with the state, whether you’re a do-it-yourselfer or want a helping hand.\n      "
          ),
        ]),
        _c("p", { staticClass: "sl__p" }, [
          _vm._v(
            "\n        If you have an existing business, add it to your online account and you’ll gain\n        access to the tools and resources to grow and maintain it.\n      "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }